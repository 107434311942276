@media (max-width: 960px) {
  .containerFooterMobile {
    background-image: url('/images/footer/footer-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 437px;
    justify-content: flex-end;
  }

  .containerFooterDesktop {
    background-image: url('/images/footer/footer-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 592px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media (min-width: 961px) {
  .containerFooterMobile {
    background-image: url('/images/footer/footer-fhd.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 437px;
    justify-content: flex-end;
  }

  .containerFooterDesktop {
    background-image: url('/images/footer/footer-fhd.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 592px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media (min-width: 1920px) {
  .containerFooterDesktop {
    background-image: url('/images/footer/footer-4k.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 592px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
